<template>
    <div class="flex flex-col h-full relative justify-center">

        <LayoutShell v-if="currentUser" :items="items" return-to="/">
            <div class="mt-4">
                <slot />
            </div>
        </LayoutShell>

    </div>
</template>

<script setup lang="ts">
const currentUser = useCurrentUser();

const items = [
    {
        label: "Retour",
        icon: "pi pi-arrow-left",
        to: "/",
    },
    {
        label: "Informations personnelles",
        icon: "pi pi-user",
        to: "/account/buyer-profile/personal-infos",
    },
    {
        label: "Expériences et compétences",
        icon: "pi pi-briefcase",
        to: "/account/buyer-profile/experiences-skills",
    },
    {
        label: "Objectifs d'acquisition",
        icon: "pi pi-flag",
        to: "/account/buyer-profile/goals",
    },
    {
        label: "Critères de recherche",
        icon: "pi pi-search",
        to: "/account/buyer-profile/search-criterias",
    },
    {
        label: "Priorités",
        icon: "pi pi-sort-amount-up",
        to: "/account/buyer-profile/priorities",
    },
    {
        label: "Disponibilité",
        icon: "pi pi-calendar",
        to: "/account/buyer-profile/availability",
    },
    {
        label: "Financement et partenariats",
        icon: "pi pi-money-bill",
        to: "/account/buyer-profile/financing-partnership",
    },
    {
        label: "Résumé",
        icon: "pi pi-file",
        to: "/account/buyer-profile/summary",
    }
];
</script>